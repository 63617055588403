import styled from "@emotion/styled";
import type { FC, ReactNode } from "react";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { IconRxDietLogoPurple } from "src/constants/icons";
import { APP_BREAKPOINT } from "src/constants/layout";
import envVariables from "src/lib/envVariables";
import translate from "src/lib/translate";
import RxDietCubit from "src/state/RxDietCubit/RxDietCubit";
import { tracker, useBloc } from "src/state/state";
import { TrackEvent } from "src/state/Track/TrackCubit";
import rxDietDescriptionDialogImgDesktop from "src/ui/assets/images/rxdiet-description-dialog-img-desktop.jpg";
import rxDietDescriptionDialogImgMobile from "src/ui/assets/images/rxdiet-description-dialog-img-mobile.jpg";
import InDialog from "src/ui/components/InDialog/InDialog";
import Translate from "src/ui/components/Translate/Translate";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import { Container, List, ListElement, ListWrapper } from "src/ui/styled/List";
import { StyledButton, StyledPill } from "../../RxDiet/RxDietCard";

//#region Styled Components
const DialogIframe = styled.iframe`
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  padding-bottom: 0 !important;
`;

const DialogContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 !important;
`;

const DialogContentGrid = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 1.5rem;
  place-items: center;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    height: 100%;
    gap: 2rem;
    grid-template-columns: 1.75fr 1fr;
    padding: 0 2.5rem 2.5rem;
  }

  @media screen and (min-width: 960px) {
    gap: 5rem;
  }
`;

const DialogContentLeft = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    border-radius: 0.5rem;
  }
`;

const DialogContentRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 1.5rem 1.5rem;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    padding: 0;
    min-width: 20rem;
  }
`;

const DialogContentImgMobile = styled.img`
  display: block;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    display: none;
  }
`;

const DialogContentImgDesktop = styled.img`
  display: none;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    display: block;
  }
`;

const StyledContainer = styled(Container)`
  > div:last-child {
    div:last-child::after {
      background: repeating-linear-gradient(
        0deg,
        var(--color-gray-lighter) 0px,
        var(--color-gray-lighter) 6px,
        transparent 6px,
        transparent 10px
      );
    }
  }
`;

const StyledContainerInner = styled.div`
  position: relative;

  &:first-of-type {
    > div {
      margin-top: 0;
    }
  }
`;

const StyledListWrapper = styled(ListWrapper)`
  margin-top: 1rem;

  &::before {
    border-width: 1px;
    background-color: #fff;
    left: 0.075em;
  }

  &::after {
    top: 1.125em;
  }
`;

const StyledListElement = styled(ListElement)`
  --check-icon: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path opacity="0.3" d="M13.5 5L6.85355 11.6464C6.65829 11.8417 6.34171 11.8417 6.14645 11.6464L3 8.5" stroke="%236235CC" stroke-width="2" stroke-linecap="round"/></svg>');
  margin: 0 0 0.25rem;

  &:first-child {
    margin-top: 0.5rem;
  }
`;
//#endregion

const listItems: {
  title: string | ReactNode;
  subtitles?: (string | ReactNode)[];
  optional?: boolean;
}[] = [
  {
    title: (
      <Translate msg="rxDiet.descriptionDialog.content.listItem_setPreferences" />
    ),
    subtitles: [
      <Translate msg="rxDiet.descriptionDialog.content.listItem_individualHouseholdProfile" />,
      <Translate msg="rxDiet.descriptionDialog.content.listItem_lifestyle" />
    ]
  },
  {
    title: (
      <Translate msg="rxDiet.descriptionDialog.content.listItem_selectWeeklyMealPlan" />
    )
  },
  {
    title: (
      <Translate msg="rxDiet.descriptionDialog.content.listItem_orderGroceries" />
    ),
    optional: true
  },
  {
    title: (
      <Translate msg="rxDiet.descriptionDialog.content.listItem_cookMeals" />
    ),
    subtitles: [
      <Translate msg="rxDiet.descriptionDialog.content.listItem_recipeInstructions" />,
      <Translate msg="rxDiet.descriptionDialog.content.listItem_nutritionalGuidance" />,
      <Translate msg="rxDiet.descriptionDialog.content.listItem_rating" />
    ]
  }
];

const RxDietNutritionProgramDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const [, { messageEventListener }] = useBloc(RxDietCubit);

  const navigate = useGoToOrBack();
  const [modalRef, setModalRef] =
    React.useState<React.RefObject<HTMLIonModalElement> | null>(null);

  const [query] = useSearchParams();
  const properUrl = query.get("url") ?? envVariables.RXDIET_IFRAME_URL;
  const title = query.get("title") ?? translate("nutritionProgram");
  const showFirstStep = query.get("showFirstStep") ?? "";

  // Used to determine whether to show description dialog or go straight to iframe
  const [isFirstStepVisible, setIsFirstStepVisible] = React.useState(
    showFirstStep === "true"
  );

  const onClose = () => {
    void modalRef?.current?.dismiss();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  React.useEffect(() => {
    window.addEventListener("message", messageEventListener);

    return () => {
      window.removeEventListener("message", messageEventListener);
    };
  }, []);

  React.useEffect(() => {
    if (!isFirstStepVisible) {
      tracker.track(TrackEvent.mealPlanningOpened);
    }
  }, [isFirstStepVisible]);

  return (
    <>
      <InDialog
        width="min(calc(100% - 4rem), 120rem)"
        height="min(calc(100% - 4rem), 70rem)"
        simple
        backdropDismiss={false}
        title={title}
        onClose={onClose}
        setRef={setModalRef}
        returnUrl={returnUrl}
      >
        <DialogContent>
          {isFirstStepVisible && (
            <DialogContentGrid>
              <DialogContentLeft>
                <DialogContentImgMobile
                  src={rxDietDescriptionDialogImgMobile}
                  alt={translate("rxDiet.img.mealPlanCards.alt")}
                />

                <DialogContentImgDesktop
                  src={rxDietDescriptionDialogImgDesktop}
                  alt={translate("rxDiet.img.mealPlanCards.alt")}
                />
              </DialogContentLeft>

              <DialogContentRight>
                <div>
                  <StyledPill style={{ marginBottom: "0.75rem" }}>
                    <small className="as-tiny">
                      <Translate msg="poweredBy" />
                    </small>
                    <IconRxDietLogoPurple />
                  </StyledPill>

                  <h4 className="as-h4-large">
                    <Translate msg="rxDiet.descriptionDialog.content.title" />
                  </h4>
                </div>

                <StyledContainer>
                  {listItems.map((listItem, listItemIndex) => (
                    <StyledContainerInner
                      key={`rxdiet-descriptionDialog-list-item-${listItemIndex}`}
                    >
                      <StyledListWrapper>
                        <h6 className="as-body2">
                          {listItem.title}
                          {listItem.optional && (
                            <>
                              {" "}
                              <small
                                className="as-tiny color-c-60 strong"
                                style={{ textTransform: "lowercase" }}
                              >
                                (<Translate msg="optional" />)
                              </small>
                            </>
                          )}
                        </h6>

                        {Boolean(listItem.subtitles?.length) && (
                          <List className="m0">
                            {listItem.subtitles?.map(
                              (subtitle, subtitleIndex) => (
                                <StyledListElement
                                  key={`rxdiet-descriptionDialog-list-item-${listItemIndex}-subtitle-${subtitleIndex}`}
                                  className={subtitleIndex === 0 ? "m0" : ""}
                                >
                                  {subtitle}
                                </StyledListElement>
                              )
                            )}
                          </List>
                        )}
                      </StyledListWrapper>
                    </StyledContainerInner>
                  ))}
                </StyledContainer>

                <StyledButton
                  onPress={() => {
                    setIsFirstStepVisible(false);
                  }}
                  className="description-dialog-button"
                >
                  <Translate msg="getStarted" /> $0
                </StyledButton>
              </DialogContentRight>
            </DialogContentGrid>
          )}

          {!isFirstStepVisible && (
            <DialogIframe src={properUrl} id="rxdiet-app" />
          )}
        </DialogContent>
      </InDialog>
    </>
  );
};

export default RxDietNutritionProgramDialog;
